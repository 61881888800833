<template>
  <div>
    <vue-headful :title="pageTitle"/>
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading/>
      </div>
      <div v-else id="body-content-area">
        <div id="pagelayout">
          <div class="columns">
            <div class="column">
              <div
                class="block"
                style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
              >
                <span class="has-text-weight-bold">Reports</span>
              </div>
              <div class="block">
                <form @submit.prevent="handleSubmit">
                  <div style="display:block; height: auto;">
                    <file-pond
                      name="file"
                      ref="pond"
                      label-idle="<div><span class='icon' style='padding-top:20px; font-size: 3rem;'><i class='fas fa-3x fa-cloud-upload-alt'></i></span></div><div>Click here or drop one or more files here to upload</div>"
                      allow-multiple="true"
                      accepted-file-types="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      :files="input.files"
                      v-on:addfile="beforeFileAdd"
                      v-on:removefile="removeFile"
                      :dropOnElement="false"
                      :dropOnPage="true"
                    />
                  </div>
                </form>
                <div class="has-background-warning has-text-white" style="padding: 0.5rem 1rem 1rem 1rem" v-show="reportErrors.length>0">
                  <span class="has-text-weight-bold">Error</span>
                  <ul id="example-1">
                    <li style="margin-top: 5px;" v-for="(err,idx) in reportErrors" :key="err + idx">{{ err }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="column">
              <div
                class="block"
                style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
              >
                <span class="has-text-weight-bold">Notifications</span>
              </div>
              <div class="block">
                <input type="checkbox" id="sendEmail" name="sendEmail" v-model="input.sendEmail">
                <label
                  for="sendEmail"
                  class="is-size-7"
                  style="padding: 0px 7px;"
                >Send email notification</label>
                <div class="field" style="padding-top: 7px; padding-left: 20px;" v-if="input.sendEmail">
                  <label class="label is-size-7">Email Template</label>
                  <div class="control">
                    <div class="select">
                      <select  v-model="input.emailTemplateId">
                        <option disabled selected>Select a template to use</option>
                        <option
                          v-for="template in emailTemplates"
                          :key="template.id"
                          :value="template.id"
                        >{{template.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="buttongroup">
            <div class="field is-grouped">
              <div class="control">
                <a class="button is-accent" :disabled="isSaving || input.files.length < 1" v-on:click="publish">
                  {{isSaving ? "Publishing" : "Publish"}}
                  <spin-loader v-if="isSaving" style="margin-left: .75rem;"/>
                </a>
              </div>
              <div class="control">
                <a class="button is-light" v-on:click="$router.go(-1)">Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
import vueFilePond, { setOptions } from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { Toast } from 'buefy/dist/components/toast'
import axios from '../axios'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
let pageName = 'New Report'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)
let uploadedFiles = []
const setCompOptions = (that) => {
  setOptions({
    instantUpload: false,
    allowRevert: false,
    allowProcess: false,
    server: {
      url: `${process.env.VUE_APP_API_URL}`,
      process: {
        url: `/reports`,
        method: 'POST',
        withCredentials: true,
        onload: response => {
          let r = JSON.parse(response)
          uploadedFiles.push(r)
          // that.input.files = this.input.files.filter()
          return r.id
        },
        onerror: response => {
          let res = JSON.parse(response)
          let msg = `Upload Error: ${res.message}`
          Toast.open(msg, 'is-danger', 'is-top', 5000)
        }
      },
      revert: (uniqueFileId, load, error) => {
        let path = `/reports/${uniqueFileId}`
        axios
          .delete(path)
          .then(res => {
            load()
          })
          .catch(err => {
            error(err)
          })
      },
      fetch: null,
      restore: null,
      load: null
    }
  })
}

/**
 * TODO:
 * whole screen file catch (user misses landing)
 * file + image preview (poster)
 * edit file name on the fly
 * validate + parse file name
 * publish
 */
export default {
  components: {
    FilePond,
    Loading
  },
  data () {
    return {
      input: {
        files: [],
        sendEmail: false,
        emailTemplateId: 0
      },
      isSaving: false,
      institutions: new Map(),
      emailTemplates: [],
      reportTypes: new Map(),
      sendEmail: false,
      isLoading: false,
      reportErrors: [],
      pageheading: pageName
    }
  },
  beforeRouteLeave (to, from, next) {
    this.beforeWindowUnload(null, next)
  },
  async created () {
    window.addEventListener('beforeunload', this.shouldLeave)
    setCompOptions(this)
    this.refreshData()
    this.input = {
      files: [],
      sendEmail: false,
      emailTemplateId: 0
    }

    uploadedFiles.length = 0
  },
  computed: {
    ...mapState([activeSite]),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    clientReportTypes () {
      let codes = Array.from(this.reportTypes.values()).filter(type => type.category === 'client')
      return new Map(codes.map(type => [type.code, type]))
    }
  },
  methods: {
    // hasChanged () {
    //   let hasChanged = false

    //   Object.keys(this.input).forEach(_key => {
    //     if (this.input[_key] !== this.inputHash[_key]) {
    //       hasChanged = true
    //     }
    //   })

    //   return hasChanged
    // },
    confirmLeave () {
      return confirm('Do you really want to leave? You have unsaved changes!')
    },
    shouldLeave (e) {
      let shouldLeave = true

      if ((this.input.files.length > 0)) {
        if (!this.confirmLeave()) {
          if (e !== null) {
            // Cancel the event
            e.preventDefault()
            // Chrome requires returnValue to be set
            e.returnValue = ''
          }

          shouldLeave = false
        }
      }

      return shouldLeave
    },
    beforeWindowUnload (e, next) {
      let shouldLeave = (this.input.files.length > 0) && !this.shouldLeave()
      if (shouldLeave) {
        next(false)
      } else {
        next()
      }
    },
    async refreshData () {
      await this.getEmailTemplates()
      await this.getInstitutionsLookup()
      await this.getReportTypesLookup()
    },
    async getEmailTemplates () {
    // TODO dedupe
      try {
        this.isLoading = true
        let response = {}
        let route = `/email-templates/search`
        let payload = { filter: { type: 'Reporting' } }
        response = await this.axios.post(route, payload)
        this.emailTemplates = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async performPublish () {
      this.input.files = []
      // TODO: email template id
      let route = `/reports/publish`
      let params = {
        params: { ids: uploadedFiles.map(f => f.id) }
      }
      if (this.input.sendEmail) { params.params['emailTemplateId'] = this.input.emailTemplateId }
      await this.axios.post(route, null, params)
      this.isSaving = false
      this.successToast('Published Reports', { name: 'Reports' })
    },
    async publish () {
      try {
        this.isSaving = true
        let files = this.$refs.pond.getFiles()

        if (files.length > 0) {
          this.$refs.pond.processFiles().then(this.performPublish)
        } else {
          this.performPublish()
        }
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        uploadedFiles.length = 0
      }
    },
    removeFile (event, file) {
      try {
        let reportMeta = this.parseReportName(file.filename)
        uploadedFiles = uploadedFiles.filter(x => x.name !== reportMeta.name)
        this.input.files = this.input.files.filter(_file => !_file.file.name.includes(reportMeta.name))
      } catch (error) {
        // do nothing
      }
    },
    async getReportTypesLookup () {
      // TODO: dedupe
      try {
        let route = `/report-types`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.reportTypes = new Map(
          response.data.records.map(i => [i.code, i])
        )
        this.reportTypesArr = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async getInstitutionsLookup () {
      // TODO: dedupe
      try {
        let route = `/institutions`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.institutions = new Map(response.data.records.map(i => [i.id, i]))
        this.institutionsArr = response.data.records
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async beforeFileAdd (evnt, file) {
      try {
        let reportMeta = this.parseReportName(file.filename)

        let err = ''
        if (!this.institutions.get(reportMeta.institutionId) && !this.reportTypes.get(reportMeta.typeCode)) {
          err = `${file.filename} references an invalid institution id ${reportMeta.institutionId} and an invalid report type code ${reportMeta.typeCode} and has been removed.`
        } else if (!this.institutions.get(reportMeta.institutionId)) {
          err = `${file.filename} references an invalid institution id ${reportMeta.institutionId} and has been removed.`
        } else if (!this.reportTypes.get(reportMeta.typeCode)) {
          err = `${file.filename} references an invalid report type code ${reportMeta.typeCode} and has been removed.`
        } else if (this.reportTypes.get(reportMeta.typeCode) && !this.clientReportTypes.get(reportMeta.typeCode)) {
          err = `${file.filename}: references an invalid report type code "${reportMeta.typeCode}" for client reports. This type of document doesn't belong here.`
        } else if (reportMeta.institutionId.toString().length !== 4 || reportMeta.typeCode.length !== 3 || ((reportMeta.yearOverride.toString().length !== 2 && reportMeta.yearOverride.toString().length !== 1 && reportMeta.yearOverride !== 0)) || (file.filename.split('-').length) <= 3) {
          err = `The filename ${file.filename} is invalid.  Please use the following format (including hyphens): aaaa-bbb-cc-ddddd.eee

  aaaa - 4-digit client/institution ID
  bbb - 3-character report type code
  cc - 2-digit override year
  ddddd - free form report name (no character limit)
  eee - supported file extension (.xlsx, .pdf)
  `
        }

        if (err.length > 0) {
          await file.abortLoad()
          this.reportErrors.push(err.trim())
          return false
        }

        this.input.files.push(file)

        // this.reportErrors = []
        return true
      } catch (error) {
        await file.abortLoad()
        this.reportErrors.push(error.message)
      }
    }
  }
}
</script>

<style scoped>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
#pagelayout {
  right: 100%;
  bottom: 0;
}
.filepond--list-scroller {
  transform: translate3d(0px, 0px, 0) !Important;
}
.filepond--drop-label {
  font-size: 1.5rem;
  color: #7fb942;
}
#example-1
{
    white-space:pre-wrap;
}
</style>
